import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography } from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { Link } from '../Link';

export interface CardContentProps {
  title?: string | undefined;
  description?: string | undefined;
  limit?: number | undefined;
  className?: string;
}

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const Button = React.forwardRef<HTMLButtonElement, any>((props, ref) =>
  useHighlightHandlers(MuiButton, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  // paddingBottom: `${theme.spacing(3)} !important`,
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: theme.spacing(3, 2),
    height: '100%',
    border: theme.theme_component_card_content_border || 'none',
    borderTop: 'none',
    pointerEvents: 'none',
  },
})) as typeof CardContent;

const CardTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  paddingBottom: theme.spacing(1),
  fontWeight: 'bold',
  color: theme.theme_component_navigation_bar_background_color,
  whiteSpace: 'inherit',
  fontSize: '20px',
  lineHeight: '26px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    lineHeight: '22px'
  }
}));

const Description = styled(Typography)(({ theme }) => ({
  height: '50px',
  marginBottom: theme.spacing(3),
  fontSize: theme.spacing(2),
  color: '#6E6E6E',
  lineHeight: '28px',
  [theme.breakpoints.up('md')]: {
    [theme.breakpoints.down('lg')]: {
      lineHeight: '20px',
    }
  },
  [theme.breakpoints.down('sm')]: {
    lineHeight: '20px',
    height: '60px',
    fontSize: '14px'
  }
}));

const LinkContainer = styled(Link)(({ theme }) => ({
  color: theme.theme_component_navigation_bar_background_color || '#0C0C0C',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '18px',
  fontWeight: 500,
  textAlign: 'center',
  textDecoration: 'none',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'left',
    justifyContent: 'flex-start'
  },
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

// Todo: HUZ2ERA-1101 - Allow Typography props to be drilled (now we have them hardcoded in our jsx).
//       Had trouble extending our CardContentCProps...
export const CardContentFHardcoded = (props: CardContentProps) => {
  const { description, title, limit, className, ...other } = props;
  const { t } = useTranslation('card_content_f');
  const body2Ref = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <CardContentStyled className={className}>
      <CardTitle
        variant="caption"
        component="span"
        noWrap
        {...other}
      >
        {getText(title || "", limit || 35)}
      </CardTitle>
      <Description ref={body2Ref} variant="body2" component="p" color="textPrimary" {...other}>
        {description && getText(description, limit || 130)}
      </Description>
        <LinkContainer href="https://cnn.it/5thingsquiz">
          Test your knowledge <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M7.41076 4.20297C7.7362 3.87753 8.26384 3.87753 8.58928 4.20297L14.4226 10.0363C14.748 10.3617 14.748 10.8894 14.4226 11.2148L8.58928 17.0481C8.26384 17.3736 7.7362 17.3736 7.41076 17.0481C7.08533 16.7227 7.08533 16.1951 7.41076 15.8696L12.6548 10.6256L7.41076 5.38148C7.08533 5.05604 7.08533 4.52841 7.41076 4.20297Z" fill="#0C0C0C"/>
</svg>

        </LinkContainer>
    </CardContentStyled>
  );
};
