import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography, useMediaQuery } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@emotion/react';

export interface CardContentProps {
  title?: string | undefined;
  description?: string | undefined;
  limit?: number | undefined;
  className?: string;
}

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  paddingBottom: `${theme.spacing(2)} !important`,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: theme.spacing(3, 2),
    height: '100%',
    border: theme.theme_component_card_content_border || 'none',
    borderTop: 'none',
    pointerEvents: 'none',
  },
})) as typeof CardContent;

const CardTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  paddingBottom: theme.spacing(1),
  fontSize: theme.spacing(4.375),
  fontWeight: 'bold',
  color: '#000'
}));

const Description = styled(Typography)(({ theme }) => ({
  height: 'auto',
  fontSize: theme.spacing(2.5),
  
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

// Todo: HUZ2ERA-1101 - Allow Typography props to be drilled (now we have them hardcoded in our jsx).
//       Had trouble extending our CardContentCProps...
export const CollectionContentB = (props: any) => {
  const { description, title, limit, className, ...other } = props;
  const body2Ref = React.useRef<HTMLInputElement | null>(null);

  const theme = useTheme();

  const md = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
     
    </>
  );
};
