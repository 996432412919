import type { PaperColorElevationProps } from '../PaperColorElevation';

import styled from '@emotion/styled';
import { PaperColorElevation } from '../PaperColorElevation';

export const CardSkeletonStyled = styled(PaperColorElevation)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: theme.theme_component_card_border_radius || theme.shape.borderRadius,
  backgroundColor: theme.theme_component_card_background_color || theme.palette.background.paper,
}));

export const CardSkeleton = (props: PaperColorElevationProps) => {
  return (<CardSkeletonStyled {...props} />);
}