import type { GameMinimalProps } from '../../types';

import * as React from 'react';
import styled from '@emotion/styled';
import { useAppContext } from '../../contexts/AppContext';
import { Link } from '../Link';

// Todo: I had to make all of these properties optional. Otherwise we get typescript errors all over the place.
//       I don't really like this approach. Better extend these props only in the place where its needed?
export interface CardContentEProps {
  game?: GameMinimalProps;
  width?: number;
  height?: number;
  pointX?: number;
  pointY?: number;
}

const CardCarousel = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  transition: 'transform 0.2s cubic-bezier(0.5, 0, 0.1, 1)',
  cursor: 'pointer',
}));

const CarouselCardContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.5),
  height: '100%',
}));

const CarouselCardCover = styled('img')(({ theme }) => ({
  backgroundColor: theme.palette.grey['900'],
  width: '100%',
  height: '100%',
  borderRadius: theme.spacing(1),
  boxSizing: 'border-box',
  border: '1px solid transparent',
  ' -webkit-box-shadow': `0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2)`,
  boxShadow: `0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);`,
  '-moz-box-shadow': `0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)`,
}));

export const CardContentE = (props: CardContentEProps) => {
  const { game, width, height, pointX, pointY } = props;
  const appContext = useAppContext();
  const { assetUrl } = appContext.useConfig();
  let scaleTimeout: ReturnType<typeof setTimeout>;

  const onMouseEnter = (e: React.MouseEvent) => {
    let _target = e.target as HTMLDivElement;
    while (_target.classList[0] !== 'card-carousel') {
      // @ts-ignore
      _target = _target.parentNode;
    }

    let _cover = _target.querySelector('.carousel-card-cover');
    // @ts-ignore
    _cover.style.border = `2px solid aqua`;
    if (_target.style.width !== '364px') {
      scaleTimeout = setTimeout(() => {
        // @ts-ignore
        _target.style.zIndex = 1;
        _target.style.transform = _target.style.transform + ' scale(1.2)';
      }, 400);
    }
  };

  const onMouseLeave = (e: React.MouseEvent) => {
    clearTimeout(scaleTimeout);
    let _target = e.target as HTMLDivElement;
    while (_target.classList[0] !== 'card-carousel') {
      // @ts-ignore
      _target = _target.parentNode;
    }

    _target.style.transform = _target.style.transform.replace(' scale(1.2)', '');
    // @ts-ignore
    _target.style.zIndex = null;

    const _cover = _target.querySelector('.carousel-card-cover');
    // @ts-ignore
    _cover.style.border = null;
    // @ts-ignore
    _cover.style.borderRadius = null;
  };

  if (!game) return <></>;

  return (
    <CardCarousel
      className="card-carousel"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        transform: `translateX(${pointX}px) translateY(${pointY}px)`,
      }}
    >
      <CarouselCardContent className="carousel-card-content">
        <Link href={{ pathname: '/game/[slug]', query: { slug: game.slug } }} title={game?.name || undefined}>
          <CarouselCardCover
            className="carousel-card-cover"
            src={`${assetUrl}${game.images.cover16x9 || game.images.cover}/`}
          />
        </Link>
      </CarouselCardContent>
    </CardCarousel>
  );
};
