import type { CardContentProps } from './CardContentA';

import * as React from 'react';
import styled from '@emotion/styled';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography } from '@mui/material';
import CardContent from '@mui/material/CardContent';

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '100%',
  borderTop: 'none',
  pointerEvents: 'none',
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
})) as typeof CardContent;

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

// Todo: HUZ2ERA-1101 - Allow Typography props to be drilled. Had trouble extending our CardContentBProps...
export const CardContentB = (props: CardContentProps) => {
  const { title, limit, className, ...other } = props;
  const captionRef = React.useRef<HTMLInputElement | null>(null);

  return (
    <>
      {title && (
        <CardContentStyled className={className}>
          <Typography
            ref={captionRef}
            sx={{ width: '100%', display: 'inline-block' }}
            variant="caption"
            color="textPrimary"
            component="p"
            noWrap
            {...other}
          >
            {getText(title, limit || 35)}
          </Typography>
        </CardContentStyled>
      )}
    </>
  )
}