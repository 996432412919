import { useCursorHandlers } from '../../../contexts/CursorContext/Cursor';
import { useTheme } from '@mui/material/styles';

export const useEditorHandlers = (name: string) => {
  const theme = useTheme();

  return useCursorHandlers({
    name,
    styles: [
      {
        name: 'theme_component_card_background_color',
        label: 'Background color',
        type: 'color',
        default: theme.palette.background.paper,
      },
      {
        name: 'theme_component_card_border_radius',
        label: 'Border radius',
        type: 'number',
        endAdornment: 'px',
        default: theme.shape.borderRadius,
      },
      {
        name: 'theme_component_card_box_shadow',
        label: 'Box shadow',
        type: 'text',
        default: theme.shadows[3],
      },
      {
        type: 'divider',
      },
      {
        type: 'header',
        label: 'Content',
      },
      {
        name: 'theme_component_card_content_border',
        label: 'Border',
        placeholder: '1px solid #fff',
        type: 'text',
        default: 'none',
      },
      {
        name: 'theme_component_card_content_button_color',
        label: 'Button label color',
        type: 'color',
        default: theme.palette.text.primary,
      },
      {
        name: 'theme_component_card_content_button_background',
        label: 'Button background',
        type: 'color',
        default: theme.palette.common.white,
      },
      {
        name: 'theme_component_card_content_button_hover_background_color',
        label: 'Button hover background color',
        type: 'color',
        default: theme.palette.action.hover,
      },
      {
        name: 'theme_component_card_content_button_active_background_color',
        label: 'Button active background color',
        type: 'color',
        default: theme.palette.action.active,
      },
    ],
    configuration: [
      {
        name: 'component_card_width',
        label: 'Width',
        type: 'number',
        endAdornment: 'px',
        default: 512,
        helperText: 'Specify the width of your card. The card remains responsive, but based on the width and height; an aspect ratio will be set.',
      },
      {
        name: 'component_card_height',
        label: 'Height',
        type: 'number',
        default: 288,
        endAdornment: 'px',
      },
      {
        name: 'component_game_carousel_card_type',
        label: 'Card Type',
        type: 'select',
        default: 'simple',
        options: ['default', 'simple', 'extensive', 'partial', 'big']
      },
    ],
  });
}